import React, { useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Polyline, Polygon, Popup, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { makeStyles } from '@mui/styles'
import L from 'leaflet'
import Controls from './Controls'

// Styles
const containerStyle = {
    width: '100%',
    height: '300px'
}
const greenColor = '#14b67d'

const useStyles = makeStyles({
    exportButton: {
        backgroundColor: 'white',
        borderColor: greenColor,
        color: greenColor,
        '&:hover': {
            backgroundColor: greenColor,
            color: 'white'
        }
    }
})

const { ExportButton } = Controls

// Icône personnalisée pour les marqueurs (Leaflet)
const customIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41]
})

// Composant pour ajuster automatiquement le zoom
function AutoZoom({ data }) {
    const map = useMap()

    useEffect(() => {
        if (!data) return

        if (Array.isArray(data) && data.length > 0) {
            // Calculer les limites pour un quadrillage
            const bounds = L.latLngBounds(
                data.flatMap((item) => item.coordinates.map((coord) => [coord.lat, coord.lng]))
            )
            map.fitBounds(bounds, { padding: [50, 50] })
        } else if (data?.lat && data?.lng) {
            // Centrer et appliquer un zoom par défaut pour un seul point
            map.setView([data.lat, data.lng], 13) // 13 est un zoom normal
        }
    }, [data, map])

    return null
}

export default function Map({ data }) {
    const classes = useStyles()

    // Fonction d'exportation GPX
    const exportGPX = () => {
        if (!Array.isArray(data)) return // N'exporte que les ensembles de données
        let gpxData = '<?xml version="1.0" encoding="UTF-8"?>\n'
        gpxData += '<gpx xmlns="http://www.topografix.com/GPX/1/1" version="1.1" creator="YourName">\n'

        data.forEach((item, index) => {
            gpxData += `<trk><name>Track ${index + 1}</name><trkseg>\n`
            item.coordinates.forEach((coordinate) => {
                gpxData += `<trkpt lat="${coordinate.lat}" lon="${coordinate.lng}"></trkpt>\n`
            })
            gpxData += '</trkseg></trk>\n'
        })

        gpxData += '</gpx>'

        const blob = new Blob([gpxData], { type: 'text/xml' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'export.gpx'
        link.click()
    }

    // Fonction d'exportation KML
    const exportKML = () => {
        if (!Array.isArray(data)) return // N'exporte que les ensembles de données
        let kmlData = '<?xml version="1.0" encoding="UTF-8"?>\n'
        kmlData += '<kml xmlns="http://www.opengis.net/kml/2.2">\n'

        data.forEach((item, index) => {
            kmlData += `<Placemark>\n`
            kmlData += `<name>Track ${index + 1}</name>\n`
            kmlData += `<LineString>\n<coordinates>\n`
            item.coordinates.forEach((coordinate) => {
                kmlData += `${coordinate.lng},${coordinate.lat} `
            })
            kmlData += `</coordinates>\n</LineString>\n</Placemark>\n`
        })

        kmlData += '</kml>'

        const blob = new Blob([kmlData], { type: 'text/xml' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'export.kml'
        link.click()
    }

    return (
        <div className="mt-[10px]">
            {/* Carte Leaflet */}
            <MapContainer style={containerStyle}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {/* Ajustement automatique du zoom */}
                <AutoZoom data={data} />

                {/* Marqueurs et polygones */}
                {Array.isArray(data) &&
                    data.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.coordinates && (
                                <>
                                    {/* Polyligne */}
                                    <Polyline
                                        positions={item.coordinates.map((coord) => [coord.lat, coord.lng])}
                                        color="red"
                                        opacity={0.7}
                                        weight={4}
                                    />
                                    {/* Polygone */}
                                    <Polygon
                                        positions={item.coordinates.map((coord) => [coord.lat, coord.lng])}
                                        color="red"
                                        fillColor="green"
                                        fillOpacity={0.35}
                                    />
                                </>
                            )}
                        </React.Fragment>
                    ))}

                {/* Si un seul marqueur (lat/lng) */}
                {data?.lat && data?.lng && (
                    <Marker position={[data.lat, data.lng]} icon={customIcon}>
                        <Popup>
                            latitude : {JSON.stringify(data.lat)}, longitude : {JSON.stringify(data.lng)}
                        </Popup>
                    </Marker>
                )}
            </MapContainer>

            {/* Boutons d'exportation */}
            <div className="flex justify-end gap-1 mt-[10px]">
                {Array.isArray(data) && (
                    <>
                        <ExportButton className={classes.exportButton} title="Exporter KML" action={exportKML} />
                        <ExportButton className={classes.exportButton} title="Exporter GPX" action={exportGPX} />
                    </>
                )}
            </div>
        </div>
    )
}
