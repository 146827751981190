import Service from '../../../applications/Service'

class DashboardService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.',
        get_error: 'Erreur lors du chargement des données.'
    }

    static load() {
        this.setUrl('/api/dashboard')
        return this
    }

    static async getAgroData() {
        try {
            this.load()
            this.setUrl(this.url + '/agro')
            const response = await this.getAll()
            return response
        } catch (error) {
            console.log(error)
            throw new Error(this.messages.get_error)
        }
    }

    static async getCommData() {
        try {
            this.load()
            this.setUrl(this.url + '/commercial')
            const response = await this.getAll()
            return response.data
        } catch (error) {
            console.log(error)
            throw new Error(this.messages.get_error)
        }
    }
}

export default DashboardService
