import React, { useEffect, useState } from 'react'
import { Typography, Container, Grid } from '@mui/material'
import DiagnosticPedologic from './parametersCard/DiagnosticPedologic'
import ExistantVegetal from './parametersCard/ExistantVegetal'
import Faune from './parametersCard/Faune'
import Density from './parametersCard/Density'
import CanopeCoverage from './parametersCard/CanopeCoverage'

function SavedParameters(props) {
    const { styles, savedParametersData, setNeedLoad } = props
    const [pedologicalDiagData, setPedologicalDiagData] = useState(null)
    const [existVegetationData, setExistVegetationData] = useState(null)
    const [existFaunaData, setExistFaunaData] = useState(null)
    const [densityData, setDensityData] = useState(null)
    const [transectData, setTransectData] = useState(null)

    useEffect(() => {
        setNeedLoad(true)
        if (savedParametersData) {
            console.log(savedParametersData)
            setPedologicalDiagData(savedParametersData?.pedologicalDiagData)
            setExistVegetationData(savedParametersData?.existVegetationData)
            setExistFaunaData(savedParametersData?.existFaunaData)
            setDensityData(savedParametersData?.densityData)
            setTransectData(savedParametersData?.transectData)

            setNeedLoad(false)
        }
    }, [savedParametersData, setNeedLoad])

    return (
        <>
            <Typography sx={styles.form_title}>Paramètres enregistrés</Typography>
            {pedologicalDiagData && <DiagnosticPedologic pedologicalDiagData={pedologicalDiagData} />}
            <Grid container sx={{ paddingTop: '1.5rem' }}>
                <Grid item xs={4}>
                    {existVegetationData && <ExistantVegetal existVegetationData={existVegetationData} />}
                </Grid>
                <Grid item xs={5}>
                    <Container>{existFaunaData && <Faune existFaunaData={existFaunaData} />}</Container>
                </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: '1.5rem' }}>
                <Grid item xs={4}>
                    {densityData && <Density densityData={densityData} />}
                </Grid>
                <Grid item xs={6}>
                    <Container>{transectData && <CanopeCoverage transectData={transectData} />}</Container>
                </Grid>
            </Grid>
        </>
    )
}

export default SavedParameters
