import React, { useContext, useEffect, useState } from 'react'
import AgroDashboardContext from './utils/hooks/AgroDashboardContext'
import { useAuth } from '../../applications/hooks/UseAuth'
import { NurseryDashboard } from './components/NurseryDashboard'
import { PLantingDashBord } from './components/PlantingDashboard'
import PlotDashboard from './components/PLotDashboard'
import { PeasantDashBord } from './components/PeasantDashBoard'
import ComDashboardContext, { Context } from './utils/hooks/CommDashboardContext'
import CommDashboard from './components/CommDashboard'

export default function Dashboard() {
    const [needLoad, setNeedLoad] = useState(false)
    const user = useAuth()

    useEffect(() => {
        if (user) {
            console.log('user : >>>>>>>>>> ', user)
        }
    }, [])

    return (
        <>
            {user.role !== 'Commercial' || user.role === 'Administrator' ? (
                <AgroDashboardContext needLoad={needLoad} setNeedLoad={setNeedLoad}>
                    <div className="flex flex-col gap-4">
                        <NurseryDashboard setNeedLoad={setNeedLoad} needLoad={needLoad} />
                        <div className="flex gap-4">
                            <div className="w-[70%]">
                                <PLantingDashBord setNeedLoad={setNeedLoad} needLoad={needLoad} />
                            </div>
                            <div className="w-[30%] mr-[10px]">
                                <PlotDashboard setNeedLoad={setNeedLoad} needLoad={needLoad} />
                            </div>
                        </div>
                        <div className="w-full">
                            <PeasantDashBord setNeedLoad={setNeedLoad} needLoad={needLoad} />
                        </div>
                    </div>
                </AgroDashboardContext>
            ) : null}
            {user.role === 'Commercial' || user.role === 'Administrator' ? (
                <ComDashboardContext needLoad={needLoad} setNeedLoad={setNeedLoad}>
                    <CommDashboard />
                </ComDashboardContext>
            ) : null}
        </>
    )
}
