import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import Controls from '../../../components/Entry/Controls'
import Notification from '../../../layouts/Notification'

import CustomerOrderService from '../services/CustomerOrder'
import DataSetsService from '../../datasets/services/DataSets'
import SectionTitle from '../../../components/Typography/SectionTitle'
import { Container } from '@mui/material'
import ContentListViewer from '../../../components/PageView/viewer/ContentListViewer'

const { Input: TextField } = Controls

const initialFValues = {
    firstname: '',
    lastname: '',
    state: '',
    email: '',
    place: '',
    phone: ''
}

export const CustomerOrderForm = (props) => {
    const { records } = props

    const dataset = DataSetsService.load()

    const [dataState, setDataState] = useState([])
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    useEffect(() => {
        if (records) {
            console.log("record ato amin'ny form : ", records)
            setValues((prevFormData) => ({
                ...prevFormData,
                firstname: records?.firstname || '',
                lastname: records?.lastname || '',
                state: records?.state || '',
                email: records?.email || '',
                place: records?.place || '',
                phone: records?.phone || ''
            }))
        }
    }, [records])

    useEffect(() => {
        dataset
            .getDataCode('statut_customer')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setDataState(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstname' in fieldValues) temp.firstname = fieldValues.firstname ? '' : 'Ce champ est requis.'
        if ('state' in fieldValues) temp.state = fieldValues.state ? '' : 'Ce champ est requis.'
        if ('email' in fieldValues) temp.email = fieldValues.email ? '' : 'Ce champ est requis.'
        if ('place' in fieldValues) temp.place = fieldValues.place ? '' : 'Ce champ est requis.'
        if ('phone' in fieldValues) temp.phone = fieldValues.phone ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('values customer to update : >>>>>>>>>>> ', values)
        if (validate()) {
            setUserHaveToPost(true)
            const dataToSend = { ...values }
            let customer = null
            let message = ''
            if (records?.id) {
                customer = await CustomerOrderService.updateCustomerOrder(records.id, dataToSend)
                message = CustomerOrderService.messages.update_success
            } else {
                customer = await CustomerOrderService.addCustomerOrder(dataToSend)
                message = CustomerOrderService.messages.create_success
            }

            if (customer) {
                setUserHaveToPost(false)
                new Notification(message, 1)
            } else {
                setUserHaveToPost(false)
                new Notification(message, 0)
            }
        }
    }

    const handleChange = (e) => {
        handleInputChange(e)
        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div>
                    <div className="flex p-4">
                        <div className="w-full p-2">
                            <>
                                <SectionTitle>Informations sur le client</SectionTitle>
                                <div className="flex gap-[40px]">
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-disabled"
                                            label="Raison sociale"
                                            name="firstname"
                                            value={values?.firstname || ''}
                                            size="small"
                                            onChange={handleChange}
                                            error={errors?.firstname}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="w-full">
                                        {/* <SelectDataSet
                                            label="Statut"
                                            value={values.state || ''}
                                            onChange={handleChange}
                                            name="state"
                                            options={dataState}
                                            error={errors?.state}
                                            isRequired={true}
                                        /> */}
                                        <Controls.AutoCompleteSelect
                                            name="state"
                                            label="Statut"
                                            options={dataState}
                                            labelKey={'label'}
                                            valueKey={'label'}
                                            value={values.state}
                                            error={errors.state}
                                            isRequired={true}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-[40px]">
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-disabled"
                                            label="Téléphone"
                                            name="phone"
                                            value={values?.phone || ''}
                                            size="small"
                                            onChange={handleChange}
                                            error={errors?.phone}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-disabled"
                                            label="Email"
                                            name="email"
                                            value={values?.email || ''}
                                            size="small"
                                            isRequired={true}
                                            onChange={handleChange}
                                            error={errors?.email}
                                        />
                                    </div>
                                </div>
                                <div className="w-full">
                                    <TextField
                                        id="outlined-disabled"
                                        label="Lieux"
                                        name="place"
                                        value={values?.place || ''}
                                        size="small"
                                        onChange={handleChange}
                                        error={errors?.place}
                                        isRequired={true}
                                    />
                                </div>
                            </>
                            <div className="flex justify-end items-end">
                                <Controls.Button
                                    type="submit"
                                    text="Modifier"
                                    haveSpin={true}
                                    disabled={userHaveToPost}
                                />
                            </div>
                            <SectionTitle>Informations de plantation</SectionTitle>
                            <div className="flex gap-[40px]">
                                <div className="w-full">
                                    <Controls.DatePicker
                                        id="outlined-disabled"
                                        label="Début de collaboration"
                                        name="collaboration_begin"
                                        value={records?.collaboration_begin || ''}
                                        size="small"
                                        onChange={handleChange}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-[40px]">
                                <div className="w-full">
                                    <TextField
                                        id="outlined-disabled"
                                        label="Surface agroforesterie"
                                        name="surfaceAgro"
                                        value={records?.surfaceAgro || ''}
                                        size="small"
                                        onChange={handleChange}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextField
                                        id="outlined-disabled"
                                        label="Surface mangrove"
                                        name="surfaceMangrove"
                                        value={records?.surfaceMangrove || ''}
                                        size="small"
                                        onChange={handleChange}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <SectionTitle>Espèces plantées</SectionTitle>
                            <div className="flex gap-[40px]">
                                <div className="w-full">
                                    <ContentListViewer
                                        values={records?.dataSpecies?.agro}
                                        label="Espèces agroforesterie"
                                    />
                                </div>
                                <div className="w-full">
                                    <ContentListViewer
                                        values={records?.dataSpecies?.mangrove}
                                        label="Espèces mangrove"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
