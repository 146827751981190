import Table from '../../../../components/DataTable/Table'

const headCells = [
    { id: 'created_date', type: 'date', label: 'Date' },
    {
        id: 'peasant',
        label: 'Code paysan',
        link: 'true',
        showView: { link: '/app/agroforestry/peasants/view', param: 'peasant' }
    },
    { id: 'peasantName', label: 'Nom & prénoms' },
    {
        id: 'plot',
        label: 'Code parcelle',
        link: 'true',
        showView: { link: '/app/agroforestry/plots/view', param: 'plot' }
    },
    { id: 'commune', label: 'Commune' },
    { id: 'fkt', label: 'Fokontany' },
    { id: 'village', label: 'Village' },
    { id: 'surface', label: 'Surface (m²)', type: 'Integer' },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: true,
        recommandation: ['plot', 'peasant']
    }
]

export default function PreregistryList(props) {
    const { haveAccessTo, currentPage, Form, drawer, service, needLoad, setNeedLoad, filterFromList } = props

    return (
        <Table
            title=""
            headCells={headCells}
            service={service}
            Form={Form}
            drawer={drawer}
            keyToSearch="lastname"
            haveAccessTo={haveAccessTo}
            currentPage={currentPage}
            setNeedLoad={setNeedLoad}
            needLoad={needLoad}
            filterFromList={filterFromList}
            hideFilterButton={true}
            hideToolbar={false}
            showSiteButton={false}
        />
    )
}
