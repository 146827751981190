import React from 'react'
import Table from '../../../../components/DataTable/Table'
import ContentListViewer from '../../../../components/PageView/viewer/ContentListViewer'
import { formatDate } from '../../../../applications/UtilFunctions'

const headCells = [
    { id: 'planting_date', type: 'date', label: 'Année', format: 'YYYY' },
    {
        id: 'plot',
        label: 'Code parcelle',
        link: 'true',
        showView: { link: '/app/agroforestry/plots/view', param: 'plot' }
    },
    {
        id: 'peasant',
        label: 'Code paysan',
        link: 'true',
        showView: { link: '/app/agroforestry/peasants/view', param: 'peasant' }
    },
    {
        id: 'peasant_name',
        label: 'Nom & prénoms'
    },
    { id: 'fkt', label: 'Fokontany' },
    { id: 'tree_planted', label: 'Arbres plantés', type: 'Integer' },
    { id: 'surface', label: 'Surface(m²)', type: 'Integer' },
    { id: 'customer', label: 'Client' },
    {
        id: 'nursery_get_out_info',
        label: "Infos provenance espèces",
        type: 'hover',
        hover: (values) => {
            console.log(values.nursery_get_out_info)
            const getOutInfoList = values?.nursery_get_out_info?.map((value) => {
                return {
                    title: value?.nursery_specie?.specie?.name,
                    contents: [
                        { label: 'Pépinière', value: value?.nursery_specie?.nursery?.code },
                        { label: 'Quantité', value: value?.quantity },
                        { label: 'Date de sortie', value: formatDate(value?.getout_date) }
                    ]
                }
            })
            return (
                <>
                    <ContentListViewer values={getOutInfoList} />
                </>
            )
        }
    },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: true,
        showView: '/app/agroforestry/plantations/view'
    }
]

function PlantationsList(props) {
    const { haveAccessTo, service, needLoad, currentPage, Form, drawer, setNeedLoad, filterFromList } = props
    return (
        <>
            <Table
                title="Liste des plantations"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                hideToolbar={false}
                currentPage={currentPage}
                Form={Form}
                filterFromList={filterFromList}
                drawer={drawer}
                hideFilterButton={true}
                showSiteButton={false}
            />
        </>
    )
}

export default PlantationsList
