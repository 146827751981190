import React, { createContext, useEffect, useState } from 'react'
import DashboardService from '../../service/DashboardService'
import Loader from '../../../../components/Entry/Loading'

export const Context = createContext({})

function ComDashboardContext(props) {
    const { children, needLoad, setNeedLoad } = props
    const [value, setValue] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        const agroData = async () => {
            DashboardService.getCommData()
                .then((res) => {
                    console.log(res)
                    setValue(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

        agroData()
        setLoading(false)
        if (needLoad) {
            setLoading(true)
        }
    }, [needLoad])

    return (
        <>
            <Loader isLoading={loading} />
            <Context.Provider value={value}>{children}</Context.Provider>
        </>
    )
}

export default ComDashboardContext
