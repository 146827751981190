import React, { useEffect, useState } from 'react'
import { Toolbar as MuiToolbar, InputAdornment, Badge, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Search } from '@mui/icons-material'
import Controls from '..//Entry/Controls'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg'
import ProvinceFilter from '../../pages/provinces/components/ProvinceFilter'

const useStyles = makeStyles((theme) => ({
    searchInput: {
        width: '100%',
        border: 0,
        '& .MuiInputBase-root': {
            borderRadius: 10,
            backgroundColor: '#FFF',
            borderRadius: 10,
            boxShadow: '0px 0px 0px 0px #d2d2d2,0px 0px 0px 0px #d2d2d2,0px 0px 5px 0px #d2d2d2'
        }
    },
    filterButton: {
        height: 0,
        width: 0,
        boxShadow: '0px 0px 0px 0px #d2d2d2,0px 0px 0px 0px #d2d2d2,0px 0px 1px 0px #d2d2d2'
    },
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '40px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2
        },
        '& .MuiBottomNavigationAction-root': {
            minWidth: '9.9vw',
            borderRadius: 10
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            background: '#F2F8F6',
            borderRadius: 10
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15
        }
    }
}))

function Toolbar(props) {
    const classes = useStyles()
    const {
        setNeedLoad,
        filter,
        setFilter,
        handleSearch,
        handleFilter,
        filterBadge,
        hideFilterButton,
        showSiteButton
    } = props

    return (
        <>
            <MuiToolbar style={{ padding: 0, alignItems: 'center' }}>
                <div className="flex gap-5" style={{ width: '100%' }}>
                    <TextField
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                        size="small"
                    />
                    {!hideFilterButton && (
                        <Controls.IconButton className={classes.filterButton} onClick={handleFilter}>
                            <Badge color="primary" variant="dot" invisible={filterBadge}>
                                <FilterIcon />
                            </Badge>
                        </Controls.IconButton>
                    )}
                </div>
            </MuiToolbar>
            {showSiteButton && (
                <>
                    <ProvinceFilter setNeedLoad={setNeedLoad} filter={filter} setFilter={setFilter} />
                </>
            )}
        </>
    )
}

export default Toolbar
