import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TableDetail from '../../../components/DataTable/TableDetail'
import PeasantTrainingService from '../services/Peasant_training'
import PlotService from '../../plot/services/Plots'
import PeasantService from '../services/Peasant'
import { useLocation } from 'react-router-dom'
import PlotView from '../../plot/components/plot/PlotView'

const headCellsTraining = [
    { id: 'description', label: 'Type de formation' },
    { id: 'former', label: 'Formateur' },
    { id: 'lieu', label: 'Lieu' },
    { id: 'training_start', type: 'date', label: 'Date de début de formation' },
    { id: 'training_end', type: 'date', label: 'Date de fin de formation' },
    { id: 'attachments', label: 'Pièces justificatives' }

    // { id: 'actions2', type: 'actions', label: 'Actions', disableSorting: true, showEdit: true, showRemove: true }
]
const headCellsPlots = [
    { id: 'code', label: 'Code parcelles', viewOnDrawer: true },
    { id: 'statut_foncier', label: 'Statut foncier' },
    { id: 'surface', label: 'Surface' },
    { id: 'regions', label: 'Région' },
    { id: 'district', label: 'District' },
    { id: 'commune', label: 'Commune' },
    { id: 'fkt', label: 'Fokontany' },
    { id: 'village', label: 'Village' },
    //{ id: 'topo', label: 'Topo séquence' },
    //{ id: 'texture', label: 'Texture' },

    // {
    //     id: 'actions',
    //     type: 'actions',
    //     label: 'Actions',
    //     disableSorting: true,
    //     showEdit: false,
    //     showRemove: false,
    //     showView: '/app/agroforestry/plots/view'
    // }
]

export const PeasantTab = (props) => {
    const { records, setNeedLoad } = props

    // const location = useLocation()
    // const segments = location.pathname.split('/')
    // const code = segments.pop()

    // const peasant_service = PeasantService.load()
    const peasantTrainingService = PeasantTrainingService.load()
    const plot_service = PlotService.load()

    // const [records, setrecords] = useState({})
    const [value, setValue] = React.useState(0)
    const [trainingData, setTrainingData] = useState([])
    const [plotData, setPlotData] = useState([])

    // useEffect(() => {
    //     fetchData()
    // }, [code])

    useEffect(() => {
        refetchData()
    }, [records?.code])

    // const fetchData = () => {
    //     if (code) {
    //         peasant_service
    //             .getByCode(code?.toString())
    //             .then((response) => {
    //                 if (response?.data) {
    //                     setrecords(response.data)
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching plot data:', error)
    //             })
    //     }
    // }

    const refetchData = () => {
        if (records?.id) {
            // Récupération Formation selon id du Paysans
            peasantTrainingService
                .getPeasantTrainingsByPeasantId(records?.id)
                .then((response) => {
                    if (response?.data) {
                        setTrainingData(response.data)
                        console.log('response.data', response.data)
                    }
                })
                .catch((error) => {
                    console.error('Error fetching training data:', error)
                })
            // Récupération Parcelle selon Code du Paysans
            plot_service
                .getPlotsByPeasantCode(records?.code?.toString())
                .then((response) => {
                    if (response?.data) {
                        setPlotData(response.data)
                    }
                })
                .catch((error) => {
                    console.error('Error fetching plot data:', error)
                })
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                // className="border-[3px] rounded-md border-green-300 p-1 w-[32%] ml-[5px]"
            >
                <Tab label="Formations Suivies" className="font-semibold" />
                <Tab label="Liste des parcelles" className="font-semibold" />
            </Tabs>
            {value === 0 && (
                <Box sx={{ p: 3 }}>
                    <div>
                        <TableDetail
                            headCells={headCellsTraining}
                            service={peasantTrainingService}
                            hideFilterButton={true}
                            data={trainingData}
                            title={'Formations suivies'}
                            onDataDelete={() => refetchData()}
                        />
                    </div>
                </Box>
            )}
            {value === 1 && (
                <Box sx={{ p: 3 }}>
                    <div>
                        <TableDetail
                            headCells={headCellsPlots}
                            service={plot_service}
                            hideFilterButton={true}
                            data={plotData}
                            title={'Liste des parcelles'}
                            useDrawer={true}
                            Form={PlotView}
                            setNeedLoad={setNeedLoad}
                        />
                    </div>
                </Box>
            )}
        </Box>
    )
}
