import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../utils/hooks/CommDashboardContext'

function CommDashboard() {
    const dataContext = useContext(Context)
    const [data, setData] = useState(null)

    useEffect(() => {
        if (dataContext !== 'undefined') {
            setData(dataContext)
        }
    }, [dataContext])

    return (
        <div>
            <div className="grid grid-cols-3 gap-4 p-6 bg-gray-100 rounded-lg shadow-md">
                {/* Carte 1 : Production de pépinière */}
                <div className="bg-green-900 text-white p-6 rounded-lg flex flex-col items-center">
                    <div className="text-4xl font-bold">{data?.plantedTreeNumber?.agro || 0}</div>
                    <div className="text-sm mt-2">Nombre d’arbres plantés</div>
                </div>

                {/* Carte 2 : Surface couverte */}
                <div className="text-black p-6 rounded-lg flex flex-col border border-gray-300">
                    <div className="text-2xl font-bold">Agroforesterie : {data?.restauredArea?.agro || 0} ha</div>
                    <div className="text-2xl font-bold">Mangrove : {data?.restauredArea?.mangrove || 0} ha</div>
                    <div className="text-sm mt-2 text-center">Total surface couvert par Bôndy</div>
                </div>

                {/* Carte 3 : Taux d'émission de carbone */}
                <div className="text-black p-6 rounded-lg flex flex-col items-center border border-gray-300">
                    <div className="text-4xl font-bold">{data?.annualSurvivalRate?.agro['2024'].survivalRate || 0}</div>
                    <div className="text-sm mt-2">Taux de survie annuel</div>
                </div>
            </div>
        </div>
    )
}

export default CommDashboard
