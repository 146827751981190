import { Container, Grid } from '@mui/material'
import Popup from '../../../../../../layouts/Popup'
import Controls from '../../../../../../components/Entry/Controls'
import { useState } from 'react'

function AddFauna(props) {
    const {
        openPopup,
        setOpenPopup,
        onSave,
        dataFauna = [],
        handleInputChange,
        errors,
        values,
        setValues,
        faunasDataAdd
    } = props

    const [data, setData] = useState({})

    const handleSaveData = () => {
        onSave([...dataFauna, data])
        setValues({ ...values, fauna: [...dataFauna, data] })
        setOpenPopup(false)
    }

    return (
        <>
            <Popup title={'Faune'} sx={{ width: '400px' }} setOpenPopup={setOpenPopup} openPopup={openPopup}>
                <Grid container>
                    <Container>
                    <Controls.AutoCompleteSelect
                            label={'Choisir une espèce'}
                            options={faunasDataAdd}
                            labelKey={'name'}
                            valueKey={'name'}
                            onChange={(e) => {
                                setData({ ...data, name: e.target.value })
                            }}
                            value={data?.name}
                        ></Controls.AutoCompleteSelect>
                    </Container>
                    <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Controls.Button onClick={handleSaveData} text="Ajouter" size={'small'} />
                    </Container>
                </Grid>
            </Popup>
        </>
    )
}

export default AddFauna
