import React, { useEffect, useState } from 'react'
import Popup from '../../../../layouts/Popup'
import { Container, Grid, Typography } from '@mui/material'
import Controls from '../../../../components/Entry/Controls'
import ContentListGroup from './content/ContentListGroup'
import ContentListPlanter from './content/ContentListPlanter'
import AddGroup from './content/AddGroup'

function PlanterGroup(props) {
    const {
        records,
        openPopup,
        setOpenPopup,
        actions,
        isGroupManagement = false,
        category,
        setGroupSaved,
        setNeedLoad,
        needLoad
    } = props
    const [isSelected, setIsSelected] = useState(1)
    const [planterContent, setPlanterContent] = useState([])
    const [planterFilterContent, setPlanterFilterContent] = useState([])
    const [presenceContent, setPresenceContent] = useState([])
    const [userHaveToAddGroup, setUserHaveToAddGroup] = useState(false)
    const [groupsValues, setGroupsValues] = useState([])

    useEffect(() => {
        if (openPopup) {
            setIsSelected(1)
        }
    }, [openPopup])

    useEffect(() => {
        if (isSelected && records) {
            console.log('Record group : ', records)
            if (records.groups.length > 0) {
                setPlanterContent(records.groups[isSelected - 1].planters)
                setPlanterFilterContent(records.groups[isSelected - 1].planters)
            }

            if (records.presence && records.presence.length > 0) {
                setPresenceContent(records.presence[isSelected - 1].MangroGroupPresence)
            }
        }
    }, [isSelected, records])

    const onPlanterViewClick = (code) => {
        actions(code)
    }

    // set onchange function for search
    const onChangeSearch = (e) => {
        const value = e
        const newPlanterContent = planterContent.filter(
            (item) =>
                item.code.toLowerCase().includes(value.toLowerCase()) ||
                item.firstname.toLowerCase().includes(value.toLowerCase()) ||
                item.lastname.toLowerCase().includes(value.toLowerCase())
        )
        setPlanterFilterContent(newPlanterContent)
    }

    return (
        <>
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title={isGroupManagement ? `Gestion des groupes - ${category}` : 'Liste planteurs'}
                customStyle={{
                    fontSize: '30px',
                    fontWeight: 'bold',
                    color: '#0fcb8f'
                }}
            >
                <Grid container sx={{ width: '700px', height: '500px' }}>
                    <Grid
                        item
                        xs={4}
                        sx={{
                            borderRight: '1px solid #ccc',
                            '& .MuiPaper-root': { border: 'none', boxShadow: 'none' }
                        }}
                    >
                        <Container>
                            <Controls.SearchField
                                placeholder="Nom, prénom, code"
                                sx={{ marginBottom: '10px' }}
                                onChange={(e) => onChangeSearch(e)}
                            />
                            {records && (
                                <div
                                    style={{
                                        height: '400px',
                                        overflowY: 'auto',
                                        scrollbarWidth: 'thin',
                                        scrollbarColor: '#grey #d0e0d7',
                                        '&::-webkit-scrollbar': {
                                            width: '8px',
                                            height: '8px',
                                            background: '#d0e0d7'
                                        }
                                    }}
                                >
                                    {records.groups.map((item, index) => {
                                        return (
                                            <>
                                                <ContentListGroup
                                                    key={index + 1}
                                                    label={item.code}
                                                    number={item.planter_number}
                                                    isSelected={isSelected === index + 1 ? true : false}
                                                    onClick={() => setIsSelected(index + 1)}
                                                />
                                            </>
                                        )
                                    })}
                                </div>
                            )}
                            {isGroupManagement && (
                                <Typography
                                    variant="caption"
                                    sx={{
                                        margin: '10px 0 10px 0',
                                        ':hover': { cursor: 'pointer', color: '#0fcb8f' },
                                        textAlign: 'center'
                                    }}
                                    component="div"
                                    color={userHaveToAddGroup ? '#0fcb8f' : 'text.secondary'}
                                    onClick={() => {
                                        setUserHaveToAddGroup(!userHaveToAddGroup)
                                    }}
                                >
                                    <i>
                                        <u>Nouveau groupe</u>
                                    </i>
                                </Typography>
                            )}
                        </Container>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        sx={{
                            '& .MuiContainer-root': { padding: '0px 0px 0px 10px' },
                            '& .MuiPaper-root': { border: 'none', boxShadow: 'none' }
                        }}
                    >
                        <Container>
                            {!userHaveToAddGroup && planterFilterContent && (
                                <ContentListPlanter
                                    setOpenPopup={setOpenPopup}
                                    setNeedLoad={setNeedLoad}
                                    isGroupManagement={isGroupManagement}
                                    actions={actions}
                                    planterContent={planterFilterContent}
                                    onClick={(code) => onPlanterViewClick(code)}
                                    absent={false}
                                    presenceContent={presenceContent}
                                />
                            )}
                            {!userHaveToAddGroup && planterFilterContent.length === 0 && (
                                <Container
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%'
                                    }}
                                >
                                    (Aucun{' '}
                                    {records?.category && records?.category === 'AgroGroups' ? 'paysan' : 'planteur'}{' '}
                                    dans le groupe.)
                                </Container>
                            )}
                            {userHaveToAddGroup && (
                                <>
                                    <div>
                                        <AddGroup
                                            setGroupSaved={setGroupSaved}
                                            village={records}
                                            category={records?.category}
                                            setUserHaveToAddGroup={setUserHaveToAddGroup}
                                            setOpenPopup={setOpenPopup}
                                        />
                                    </div>
                                </>
                            )}
                        </Container>
                    </Grid>
                </Grid>
            </Popup>
        </>
    )
}

export default PlanterGroup
