import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import { FaTrashAlt, FaUpload } from 'react-icons/fa'
import AppPlantation from '../../application/AppPlantation'
import Controls from '../../../../components/Entry/Controls'
import Subtitle from '../../../../components/Typography/Subtitle'
import Notification from '../../../../layouts/Notification'

function UploadTrackingPoints(props) {
    const { setUserHaveToUploadTrackingPoints } = props
    const [fileName, setFileName] = useState('')
    const [file, setFile] = useState(null)
    const app = new AppPlantation()
    const [trackingPointsValues, setTrackingPointsValues] = useState(null)
    const [error, setError] = useState(null)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [userFinishImportation, setUserFinishImportation] = useState(false)

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setFile(event.target.files[0])
            setFileName(event.target.files[0].name)
        } else {
            setFileName('')
        }
    }

    const handleTreatFile = async () => {
        app.setFile(file)
        await app.generateData()
        console.log(app.getData())
        setTrackingPointsValues(app.getData())
    }

    const handleSaveData = async () => {
        setUserHaveToPost(true)
        app.saveData(trackingPointsValues).then((result) => {
            if (result) {
                console.log(result)
                setUserHaveToPost(false)
                new Notification("Processus d'importation effectué.", 1)
                setUserFinishImportation(true)
                const resultsError = result.filter((item) => item.error === true)
                const error = resultsError.reduce((acc, cur) => {
                    acc[cur.message] = (acc[cur.message] || 0) + 1
                    return acc
                }, {})
                // setError(error)
                const formatedError = []
                for (let item in error) {
                    formatedError.push({ message: item, number: error[item] })
                }

                setError(formatedError)
            } else {
                new Notification("Erreur d'importation.", 0)
                setUserHaveToPost(false)
                setUserFinishImportation(true)
            }
        })
    }

    error && console.log(error)

    return (
        <div style={{ width: '500px' }}>
            <Button
                style={{
                    textTransform: 'none',
                    color: '#fff'
                }}
                variant="contained"
                component="label"
                startIcon={<FaUpload />}
            >
                Importer
                <input type="file" style={{ display: 'none' }} onChange={handleFileChange} />
            </Button>
            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                {fileName ? (
                    <>
                        <p>Fichier importé : {fileName}</p>
                        <Controls.Button text="Traiter" onClick={handleTreatFile} />
                    </>
                ) : (
                    <p>(Informations du fichier)</p>
                )}
            </div>
            {trackingPointsValues && (
                <>
                    <Typography variant="p" component="div" style={{ margin: '10px 0 10px 0' }} fontSize={12}>
                        <b>Nombre de lignes : </b> {trackingPointsValues.length}
                        <br />
                    </Typography>
                    <div
                        style={{
                            overflowY: 'scroll',
                            height: '250px',
                            border: '1px solid #ccc',
                            padding: '10px',
                            borderRadius: '5px',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#d0e0d7 #E7F2EE',
                            '&::-webkit-scrollbar': {
                                width: '8px',
                                height: '8px',
                                background: '#d0e0d7'
                            }
                        }}
                    >
                        {trackingPointsValues?.map((item, key) => {
                            return (
                                <div
                                    key={key}
                                    style={{
                                        marginBottom: '10px',
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <Subtitle
                                        style={{
                                            fontSize: '0.75rem',
                                            color: '#677788',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        {key + 1} - {item.iD_points}
                                        <FaTrashAlt
                                            style={{
                                                cursor: 'pointer',
                                                color: 'red',
                                                alignSelf: 'center'
                                            }}
                                            onClick={() => {
                                                setTrackingPointsValues(
                                                    trackingPointsValues.filter(
                                                        (point) => point.iD_points !== item.iD_points
                                                    )
                                                )
                                            }}
                                        />
                                    </Subtitle>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        style={{
                                            fontSize: '0.7rem'
                                        }}
                                    >
                                        <div>fokontany : {item?.Fokontany}</div>
                                        <div>X : {item?.X}</div>
                                        <div>Y : {item?.Y}</div>
                                    </Typography>
                                </div>
                            )
                        })}
                    </div>
                    <div
                        style={{
                            marginTop: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px'
                        }}
                    >
                        {error &&
                            error.length > 0 &&
                            error.map((item, key) => (
                                <Typography
                                    key={key}
                                    variant="body2"
                                    color="text.secondary"
                                    style={{
                                        fontSize: '0.7rem'
                                    }}
                                >
                                    <div>
                                        <b>{item.message}</b> : {item.number}
                                    </div>
                                </Typography>
                            ))}
                        {userFinishImportation ? (
                            <Controls.Button
                                text="Terminer"
                                size="large"
                                onClick={() => setUserHaveToUploadTrackingPoints(false)}
                            />
                        ) : (
                            <Controls.Button
                                text="Valider"
                                size="large"
                                onClick={handleSaveData}
                                haveSpin={true}
                                disabled={userHaveToPost}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default UploadTrackingPoints
