import React, { useState } from 'react'
import { useAuth } from '../../applications/hooks/UseAuth'
import PageHeader from '../../components/Typography/PageHeader'
import LandList from './components/listView/LandList'
import LandService from './services/Land'
import ToolbarButton from '../../components/DataTable/ToolbarButton'
import LandDetailsPage from './components/contentView/LandDetailsPage'

const CURRENT_PAGE = 'administration_species'

function Land() {
    const { haveAccessTo } = useAuth()
    const useDrawer = true
    const service = LandService.load()
    const [needLoad, setNeedLoad] = useState(true)
    return (
        <>
            <PageHeader title={'Informations du terrain à restaurer'} subTitle={'Infos terrain à restaurer'}>
                <ToolbarButton
                    moduleTitle="d'un terrain à restaurer"
                    service={service}
                    drawer={useDrawer}
                    haveAccessTo={haveAccessTo}
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    isContentView={true}
                    link="/app/mangrove/lands/new"
                    Form={LandDetailsPage}
                />
            </PageHeader>

            <LandList
                service={service}
                needLoad={needLoad}
                drawer={useDrawer}
                Form={LandDetailsPage}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                currentPage={CURRENT_PAGE}
            />
        </>
    )
}

export default Land
