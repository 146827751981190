import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ProvinceService from '../../provinces/services/Provinces'
import DataSetsService from '../../datasets/services/DataSets'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import { Container, Grid, Typography, setRef } from '@mui/material'
import Controls from '../../../components/Entry/Controls'
import PeasantService from '../services/Peasant'
import { useAuth } from '../../../applications/hooks/UseAuth'
import Notification from '../../../layouts/Notification'
import SpeciesService from '../../species/services/Species'

const { Input: TextField } = Controls

const initialFValues = {
    firstname: '',
    lastname: '',
    phone: '',
    genre: '',
    group: '',
    member_since: new Date().toISOString(),
    dob: new Date().toISOString(),
    martial_status: '',
    activity: '',
    address: '',
    photo: '',
    contract_file: ''
}

const styles = {
    form_title: {
        padding: `10px 0px 20px 0px `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    }
}

const PeasantForm = (props) => {
    const { addOrEdit, setNeedLoad, userHaveToModify, userData = null, setUserHaveToModify } = props
    const navigate = useNavigate()
    const initialOption = []
    const [regions, setRegions] = useState(initialOption)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [photo, setPhoto] = useState(null)
    const [contract_file, setContract_file] = useState(null)

    const [districts, setDistricts] = useState(initialOption)
    const [selectedDistrict, setSelectedDistrict] = useState(null)

    const [communes, setCommunes] = useState(initialOption)
    const [selectedCommune, setSelectedCommune] = useState(null)

    const [villages, setVillages] = useState(initialOption)
    const [selectedVillage, setSelectedVillage] = useState(null)

    const [fkts, setFkts] = useState(initialOption)
    const [selectedFkt, setSelectedFkt] = useState(null)

    const [sites, setSites] = useState(initialOption)
    const [selectedSite, setSelectedSite] = useState(null)

    const [groups, setGroups] = useState(initialOption)
    const [species, setSpecies] = useState(null)
    const [genreOption, setGenreOption] = useState([])
    const [martialStatusOption, setMartialStatusOption] = useState([])
    const [peasantStateOption, setPeasantStateOption] = useState([])
    const [speciesOptions, setSpeciesOptions] = useState([])
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [peasantActivityOption, setPeasantActivityOption] = useState([])
    const [proprietaryLinkOption, setProprietaryLinkOption] = useState([])
    const [reforestationReasonOption, setReforestationReasonOption] = useState([])

    const ProvinceApi = ProvinceService.load()
    const dataset = DataSetsService.load()
    const specieService = SpeciesService.load()

    const { user } = useAuth()

    useEffect(() => {
        setNeedLoad(true)

        if (!userHaveToModify) {
            ProvinceApi.getRegions().then((res) => {
                setRegions(res?.data)
            })
        }

        dataset
            .getDataCode('genre')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setGenreOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
        dataset
            .getDataCode('martial_status')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setMartialStatusOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset.getDataCode('statut_paysan').then((response) => {
            if (response?.data) {
                const dataArray = JSON.parse(response.data[0].database)
                setPeasantStateOption(dataArray)
            }
        })
        dataset.getDataCode('peasant_activity').then((response) => {
            if (response?.data) {
                const dataArray = JSON.parse(response.data[0].database)
                setPeasantActivityOption(dataArray)
            }
        })
        dataset.getDataCode('reforestation_reason').then((response) => {
            if (response?.data) {
                const dataArray = JSON.parse(response.data[0].database)
                setReforestationReasonOption(dataArray)
            }
        })
        dataset.getDataCode('proprietary_link').then((response) => {
            if (response?.data) {
                const dataArray = JSON.parse(response.data[0].database)
                setProprietaryLinkOption(dataArray)
            }
        })

        specieService
            .getAllSpecies()
            .then((res) => {
                if (res) {
                    setSpecies(res)
                }
            })
            .catch((error) => {
                console.log(error)
            })

        setNeedLoad(false)
    }, [userHaveToModify])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedRegion) setNeedLoad(true)
            ProvinceApi.getRegionsChild(selectedRegion).then((res) => {
                setDistricts(res?.data)
            })
        }
    }, [selectedRegion])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedDistrict) setNeedLoad(true)
            ProvinceApi.getDistrictChild(selectedDistrict).then((res) => {
                setCommunes(res?.data)
            })
        }
    }, [selectedDistrict])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedCommune) setNeedLoad(true)
            ProvinceApi.getCommuneChild(selectedCommune).then((res) => {
                setFkts(res?.data)
            })
        }
    }, [selectedCommune])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedFkt) setNeedLoad(true)
            ProvinceApi.getFokontanyChild(selectedFkt).then((res) => {
                setVillages(res?.data)
            })
        }
    }, [selectedFkt])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedVillage) setNeedLoad(true)
            ProvinceApi.getVillages(selectedVillage).then((res) => {
                setSites(res?.data)
            })
        }
    }, [selectedVillage])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedSite) setNeedLoad(true)
            ProvinceApi.getAgroGroupe(selectedSite).then((res) => {
                setGroups(res?.data.filter((item) => item.name !== 'Administration'))
            })
        }
    }, [selectedSite])

    useEffect(() => {
        if (species) {
            console.log('espèces : ', species)
            setSpeciesOptions(
                species.map((item) => {
                    return {
                        id: item.id,
                        label: item.name,
                        isChecked: false
                    }
                })
            )
        }
    }, [species])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstname' in fieldValues) temp.firstname = fieldValues.firstname ? '' : 'Ce champ est requis.'
        if ('lastname' in fieldValues) temp.lastname = fieldValues.lastname ? '' : 'Ce champ est requis.'
        if (!userHaveToModify) {
            if ('group' in fieldValues) temp.group = fieldValues.group.length !== 0 ? '' : 'Ce champ est requis.'
        }
        if ('genre' in fieldValues) temp.genre = fieldValues.genre.length !== 0 ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialFValues,
        true,
        validate
    )

    useEffect(() => {
        if (userData) {
            const {
                group,
                group_id,
                contract_file,
                species_requested,
                responsible_technician,
                code_qr,
                photo,
                age,
                ...rest
            } = userData
            console.log(rest)
            setValues({ ...rest })
        }
    }, [userData])

    useEffect(() => {
        if (photo) {
            setValues({ ...values, photo: photo })
        }
    }, [photo, setValues, values])

    useEffect(() => {
        if (contract_file) {
            setValues({ ...values, contract_file: contract_file })
        }
    }, [setValues, values, contract_file])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validate()) {
            const dataToSend = {
                ...values,
                user: `${user.firstname} ${user.lastname}`,
                group_id: values.group,
                species_requested: JSON.stringify(values.species_requested),
                responsible_technician: [user?.firstname, user?.lastname].join(' ')
            }

            delete dataToSend.region
            delete dataToSend.district
            delete dataToSend.commune
            delete dataToSend.fkt
            delete dataToSend.village
            delete dataToSend.site
            delete dataToSend.id
            delete dataToSend.group

            setNeedLoad(true)
            addOrEdit(dataToSend, resetForm, setUserHaveToPost)
            console.log('données envoyées : ', dataToSend)
            navigate(-1)
        }
    }

    const handleModify = async (e) => {
        e.preventDefault()
        try {
            setNeedLoad(true)
            setUserHaveToModify(false)
            const service = PeasantService.load()
            console.log(values)
            await service.update(values.id, {...values, userHistory: [user.firstname, user.lastname].join(' ')})
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectChange = (e) => {
        handleInputChange(e)
        if (e.target.name === 'region') {
            setSelectedRegion(e.target.value)
            setCommunes(initialOption)
            setFkts(initialOption)
            setVillages(initialOption)
            setSites(initialOption)
            setGroups(initialOption)
        } else if (e.target.name === 'district') {
            setSelectedDistrict(e.target.value)
            setFkts(initialOption)
            setVillages(initialOption)
            setSites(initialOption)
            setGroups(initialOption)
        } else if (e.target.name === 'commune') {
            setSelectedCommune(e.target.value)
            setVillages(initialOption)
            setSites(initialOption)
            setGroups(initialOption)
        } else if (e.target.name === 'fkt') {
            setSelectedFkt(e.target.value)
            setSites(initialOption)
            setGroups(initialOption)
        } else if (e.target.name === 'village') {
            setSelectedVillage(e.target.value)
            setGroups(initialOption)
        } else if (e.target.name === 'site') {
            setSelectedSite(e.target.value)
        }
    }

    return (
        <Form onSubmit={userHaveToModify ? handleModify : handleSubmit}>
            <div className="w-[100%]  p-2">
                {!userHaveToModify && (
                    <>
                        <Typography sx={styles.form_title}>Délimitation administrative</Typography>
                        <Grid container>
                            <Grid item xs={4}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="Région"
                                        name="region"
                                        value={values?.region || ''}
                                        options={regions || []}
                                        onChange={handleSelectChange}
                                        error={errors.region}
                                        labelKey="name"
                                        valueKey="id"
                                    />
                                    <Controls.AutoCompleteSelect
                                        label="Fokontany"
                                        name="fkt"
                                        value={values?.fkt || ''}
                                        options={fkts || []}
                                        onChange={handleSelectChange}
                                        error={errors.fkt}
                                        labelKey="name"
                                        valueKey="id"
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={4}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="District"
                                        name="district"
                                        value={values?.district || ''}
                                        options={districts || []}
                                        onChange={handleSelectChange}
                                        error={errors.district}
                                        labelKey="name"
                                        valueKey="id"
                                    />
                                    <Controls.AutoCompleteSelect
                                        label="Village"
                                        name="site"
                                        value={values?.site || ''}
                                        options={villages || []}
                                        onChange={handleSelectChange}
                                        error={errors.site}
                                        labelKey="name"
                                        valueKey="id"
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={4}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="Commune"
                                        name="commune"
                                        value={values?.commune || ''}
                                        options={communes || []}
                                        onChange={handleSelectChange}
                                        error={errors.commune}
                                        labelKey="name"
                                        valueKey="id"
                                    />
                                    <Controls.AutoCompleteSelect
                                        label="Groupe"
                                        name="group"
                                        value={values?.group || ''}
                                        options={groups || []}
                                        onChange={handleSelectChange}
                                        error={errors.group}
                                        labelKey="name"
                                        valueKey="id"
                                        isRequired={true}
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Typography sx={styles.form_title}>Informations personnelles</Typography>
                <Container>
                    <Controls.SingleImageInput
                        label="Photo"
                        onChange={handleInputChange}
                        name="photo"
                        setPhoto={setPhoto}
                    />
                </Container>
                <Grid container>
                    <Grid item xs={4}>
                        <Container>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="firstname"
                                label="Nom"
                                value={values.firstname}
                                onChange={handleInputChange}
                                error={errors.firstname}
                                isRequired={true}
                            />
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="phone"
                                type="number"
                                label="Téléphone"
                                value={values.phone}
                                onChange={handleInputChange}
                                error={errors.phone}
                            />
                            <Controls.DatePicker
                                label="Date de naissance"
                                name="dob"
                                onChange={handleInputChange}
                                type="dob"
                                value={values?.dob}
                                error={errors?.dob}
                            />
                            <Controls.AutoCompleteSelect
                                label="Statut paysan"
                                name="status"
                                value={values?.status || ''}
                                options={peasantStateOption || []}
                                onChange={handleSelectChange}
                                error={errors.status}
                                labelKey="label"
                                valueKey="label"
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={4}>
                        <Container>
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="lastname"
                                label="Prénoms"
                                value={values.lastname}
                                onChange={handleInputChange}
                                error={errors.lastname}
                                isRequired={true}
                            />
                            <Controls.AutoCompleteSelect
                                label="Statut matrimonial"
                                name="martial_status"
                                value={values?.martial_status || ''}
                                options={martialStatusOption || []}
                                onChange={handleSelectChange}
                                error={errors.martial_status}
                                labelKey="label"
                                valueKey="label"
                            />
                            <Controls.DatePicker
                                label="Début de collaboration"
                                name="member_since"
                                onChange={handleInputChange}
                                value={values?.member_since}
                                error={errors?.member_since}
                            />
                            <Controls.AutoCompleteSelect
                                label="Lien avec le propriétaire"
                                name="proprietary_link"
                                value={values.proprietary_link}
                                options={proprietaryLinkOption || []}
                                onChange={handleInputChange}
                                error={errors.proprietary_link}
                                labelKey="label"
                                valueKey="label"
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={4}>
                        <Container>
                            <Controls.AutoCompleteSelect
                                label="Genre"
                                name="genre"
                                value={values?.genre || ''}
                                options={genreOption || []}
                                onChange={handleInputChange}
                                error={errors.genre}
                                labelKey="label"
                                valueKey="label"
                                isRequired={true}
                            />
                            <Controls.AutoCompleteSelect
                                label="Activité"
                                name="activity"
                                value={values.activity}
                                options={peasantActivityOption || []}
                                onChange={handleInputChange}
                                error={errors.activity}
                                labelKey="label"
                                valueKey="label"
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="address"
                                label="Adresse"
                                value={values.address}
                                onChange={handleInputChange}
                                error={errors.address}
                            />
                            <Controls.AutoCompleteSelect
                                label="Raison de reboisement"
                                name="reforestation_reason"
                                value={values.reforestation_reason}
                                options={reforestationReasonOption || []}
                                onChange={handleInputChange}
                                error={errors.reforestation_reason}
                                labelKey="label"
                                valueKey="label"
                            />
                        </Container>
                    </Grid>
                </Grid>
                {!userHaveToModify && (
                    <>
                        <Grid container>
                            <Grid item xs={4}>
                                <Container>
                                    <Controls.MultipleSelect
                                        needNumber={true}
                                        onChange={handleInputChange}
                                        name="species_requested"
                                        label="Espèces demandées"
                                        options={speciesOptions || null}
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={8}>
                                <Container>
                                    <Controls.MultipleFileInput
                                        label="Fichier de contrats"
                                        onChange={handleInputChange}
                                        name="contract_file"
                                        setValues={setContract_file}
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                    </>
                )}
            </div>
            <div className="flex justify-end items-end m-5 p-5">
                <Controls.Button
                    type="submit"
                    text={userHaveToModify ? 'Modifier' : 'Enregistrer'}
                    size="large"
                    haveSpin={true}
                    disabled={userHaveToPost}
                />
            </div>
        </Form>
    )
}

export default PeasantForm
