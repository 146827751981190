import { Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import CustomSelect from '../../../components/Entry/CustomSelect'
import { DashboardPercent } from '../utils/DashboardPercent'
import { DashboardInput } from '../utils/DashboardInput'
import logo1 from '../../../assets/image/dashboard1.png'
import logo2 from '../../../assets/image/dashboard2.png'
import CustomerService from '../../mangrove_planting/services/Customer'
import SpeciesService from '../../species/services/Species'
import PlantationSpeciesService from '../../plantations/services/PlantationSpecies'
import PlantationService from '../../plantations/services/Plantation'

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: 10,
        backgroundColor: '#F2F8F6',
        '& .MuiPaper-root': {
            border: 'none',
            boxShadow: 'none'
        }
    }
}))

const TablePaper = styled(Paper)(({ theme }) => ({
    boxShadow: '0px 5px 5px -3px #d2d2d2,0px 8px 10px 1px #d2d2d2,0px 3px 14px 2px #d2d2d2'
}))

export const PLantingDashBord = () => {
    const classes = useStyles()

    const [plantations, setPlantations] = useState([])
    const [plantationSpecieId, setPlantationSpecieId] = useState([])
    const [planting, setPlanting] = useState([])
    const [customers, setCustomers] = useState([])
    const [species, setSpecies] = useState([])
    const [values, setValues] = useState({
        customer: '',
        specie: '',
        year: new Date().getFullYear()
    })

    useEffect(() => {
        CustomerService.getAllCustomers()
            .then((users) => {
                if (users) {
                    setCustomers(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    useEffect(() => {
        SpeciesService.getAllSpecies()
            .then((users) => {
                if (users) {
                    setSpecies(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])
    useEffect(() => {
        PlantationService.getAllPlantation()
            .then((users) => {
                if (users) {
                    const filtered = users.filter((user) => new Date(user.planting_date).getFullYear() == values.year)
                    let filteredPlantations = [...filtered]

                    if (plantationSpecieId.length !== 0) {
                        if (values.customer) {
                            filteredPlantations = filtered.filter((plantation) => {
                                const matchingPlantationSpecieId = plantationSpecieId.data.find(
                                    (specie) => specie.plantation_id === plantation.id
                                )
                                return (
                                    matchingPlantationSpecieId &&
                                    matchingPlantationSpecieId.customer === values.customer
                                )
                            })
                        } else {
                            filteredPlantations = filtered.filter((plantation) => {
                                const matchingPlantationSpecieId = plantationSpecieId.data.find(
                                    (specie) => specie.plantation_id === plantation.id
                                )
                                return matchingPlantationSpecieId
                            })
                        }
                    }

                    setPlantations(filteredPlantations)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [values.year, values.customer, plantationSpecieId])

    useEffect(() => {
        if (values.specie) {
            PlantationSpeciesService.getBySpecieId(values.specie.toString())
                .then((users) => {
                    if (users) {
                        setPlantationSpecieId(users)
                    }
                })
                .catch((error) => {
                    console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
                })
        } else {
            PlantationSpeciesService.getAllPlantationSpecies()
                .then((users) => {
                    if (users) {
                        const filtered = users.filter(
                            (user) => new Date(user.plantation.planting_date).getFullYear() == values.year
                        )
                        if (filtered) {
                            setPlanting(filtered)
                        } else {
                            setPlanting(users)
                        }
                    }
                })
                .catch((error) => {
                    console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
                })
        }
    }, [values.specie, values.year])

    const getYears = () => {
        const currentYear = new Date().getFullYear()
        const options = []
        for (let i = currentYear - 4; i <= currentYear + 4; i++) {
            options.push(i)
        }
        return options
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }))
    }

    const totalSurface = plantations?.reduce((acc, surface) => acc + surface.surface, 0)
    const totalPlant = plantations?.reduce((acc, plant) => acc + plant.tree_planted, 0)

    const totalSurfacePlanting = planting?.reduce((acc, surface) => acc + surface.plantation.surface, 0)
    const totalPlantPLanting = planting?.reduce((acc, plant) => acc + plant.plantation.number_tree_planted, 0)

    const formatNumber = (number) => {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }

    console.log('plantations with specie', plantationSpecieId)
    console.log('planting', planting, totalSurfacePlanting, totalPlantPLanting)

    return (
        <Paper className={classes.pageContent} elevation={0} style={{ backgroundColor: '#F2F8F6' }}>
            <TablePaper style={{ padding: 10, marginTop: 20, borderRadius: 10 }} elevation={8}>
                <div className="w-full">
                    <div className="flex justify-between">
                        <div>
                            <p className="font-semibold text-lg">Plantations</p>
                        </div>
                        <div>
                            <select
                                className="bg-[#0FCB8F] w-24 h-8 rounded-md border-none text-white"
                                name="year"
                                id="year"
                                value={values.year}
                                onChange={handleChange}
                            >
                                {getYears().map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="flex gap-[40px] mb-[15px] w-fullmt-[16px]">
                        <div className="w-full">
                            <CustomSelect label="Régions" />
                        </div>
                        <div className="w-full">
                            <CustomSelect label="Fokontany" />
                        </div>
                        <div className="w-full">
                            <CustomSelect
                                label="Client"
                                options={customers}
                                labelKey="customer_name"
                                valueKey="id"
                                name="customer"
                                value={values.customer || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="w-full">
                            <CustomSelect
                                label="Espèces"
                                name="specie"
                                value={values?.specie || ''}
                                onChange={handleChange}
                                options={species}
                                labelKey="name"
                                valueKey="id"
                            />
                        </div>
                    </div>

                    <div className="flex gap-[20px] w-[60%]">
                        <DashboardInput
                            title="Nombre d'arbres plantés"
                            showImg={true}
                            textColor="text-green-500"
                            src={logo1}
                            value={values.specie ? formatNumber(totalPlant) : formatNumber(totalPlantPLanting)}
                        />
                        <DashboardInput
                            title="Surface plantées "
                            showImg={true}
                            textColor="text-yellow-500"
                            src={logo2}
                            value={`${
                                values.specie ? formatNumber(totalSurface) : formatNumber(totalSurfacePlanting)
                            } ha`}
                        />
                    </div>

                    <div className="flex justify-between gap-[20px] mt-[40px] mb-[20px] w-[45%]">
                        <DashboardPercent text="Taux de survie" value={20} />
                    </div>
                </div>
            </TablePaper>
        </Paper>
    )
}
