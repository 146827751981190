import React, { useContext, useState } from 'react'
import { Table, TableHead, TableRow, TableCell, TablePagination, TableSortLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { GlobalContext } from './UseContext'
import * as XLSX from 'xlsx'

const useStyles = makeStyles((theme) => ({
    table: {
        '& thead th': {
            fontWeight: '600',
            padding: '15px 5px',
            backgroundColor: '#FFFFFF',
            textAlign: 'left'
        },
        '& tbody td': {
            fontWeight: '200',
            padding: '15px 5px',
            textAlign: 'left'
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer'
        }
    }
}))

export default function useTable(records, headCells, filterFn) {
    const classes = useStyles()

    const pages = [10, 15, 20, 25, 50, 100]
    const globalContext = useContext(GlobalContext)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(globalContext?.value?.rowsPerPage || 25)
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()

    const TblContainer = (props) => (
        <Table className={classes.table} stickyHeader>
            {props.children}
        </Table>
    )

    const TblHead = (props) => {
        const { classes } = props

        const handleSortRequest = (cellId) => {
            const isAsc = orderBy === cellId && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(cellId)
        }
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            className={classes.cellClass}
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.disableSorting ? (
                                headCell.label
                            ) : (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={() => {
                                        handleSortRequest(headCell.id)
                                    }}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    const TblPagination = () => (
        <TablePagination
            component="div"
            page={page}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={records?.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(newRowsPerPage)
        globalContext.setValue({ ...globalContext.value, rowsPerPage: newRowsPerPage })
        setPage(0)
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index])
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis?.map((el) => el[0])
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }

    const recordsAfterPagingAndSorting = () => {
        return stableSort(filterFn.fn(records), getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            (page + 1) * rowsPerPage
        )
    }

    // Export to Excel functionality
    const exportToExcel = (fileName = 'table_data.xlsx') => {
        const filteredRecords = filterFn.fn(records) || []
        const structuredRecords = filteredRecords.map((record) => {
            const structuredRecord = {}
            headCells.forEach((headCell) => {
                headCell.type !== 'actions' && (structuredRecord[headCell.label] = record[headCell.id])
            })
            return structuredRecord
        })
        const worksheet = XLSX.utils.json_to_sheet(structuredRecords)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        XLSX.writeFile(workbook, fileName)
    }

    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        exportToExcel
    }
}
