import React from 'react'
import Table from '../../../../components/DataTable/Table'

const headCells = [
    {
        id: 'code_plat_drain',
        label: 'ID plate bande',
        link: 'true',
        showView: { link: '/app/mangrove/nursery/plat/edit', param: 'plat_drain_id' }
    },
    // { id: 'plat_drain_quantity', label: 'Nombre de jeunes plants produits' },
    { id: 'species', label: 'Espèces', type: 'object', cell: 'name' },
    { id: 'nbr_output', label: 'Nombre de jeunes plants sortis', type: 'Integer' },
    { id: 'nbr_remaining', label: 'Nombre de jeunes plants restants', type: 'Integer' },
    { id: 'release_date', label: 'Date de sortie', type: 'date' },
    { id: 'planting_id', label: 'Parcelle affectation ' },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: true,
        showView: '/app/mangrove/nursery/outing/edit',
        editPath: '/app/mangrove/nursery/outing/edit'
    }
]

function OutingTable(props) {
    const { haveAccessTo, service, needLoad, currentPage, Form, setNeedLoad, drawer } = props
    return (
        <>
            <Table
                title="Sortie pépinière"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                drawer={drawer}
                hideFilterButton={true}
                showSiteButton={true}
            />
        </>
    )
}

export default OutingTable
