import React from 'react'
import Table from '../../../../components/DataTable/Table'

import { Box, Chip, Typography } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

const headCells = [
    { id: 'category', label: 'Catégorie' },
    { id: 'designation', label: 'Désignation' },
    { id: 'assignment', label: 'Affectation' },
    { id: 'date_purchased', label: "Date d'entrée", type: 'date' },
    { id: 'quantity', label: 'Quantité', type: 'Integer' },
    {
        id: 'stateList',
        label: 'Etat',
        // type: 'hover',
        // hover: (value) => {
        //     const status = {
        //         Neuf: { title: 'Neuf', color: 'success', icon: <CheckCircleOutlineOutlinedIcon /> },
        //         Réparé: { title: 'Réparé', color: 'info', icon: <CheckCircleOutlineOutlinedIcon /> },
        //         Usagé: { title: 'Usagé', color: 'warning', icon: <ErrorOutlineOutlinedIcon /> },
        //         Endommagé: { title: 'Endommagé', color: 'error', icon: <HighlightOffOutlinedIcon /> },
        //         Défectueux: { title: 'Défectueux', color: 'error', icon: <HighlightOffOutlinedIcon /> },
        //         Obsolète: { title: 'Obsolète', color: 'warning', icon: <ErrorOutlineOutlinedIcon /> },
        //         Bon: { title: 'Bon', color: 'success', icon: <CheckCircleOutlineOutlinedIcon /> },
        //         Moyen: { title: 'Moyen', color: 'warning', icon: <ErrorOutlineOutlinedIcon /> },
        //         Mauvais: { title: 'Mauvais', color: 'error', icon: <HighlightOffOutlinedIcon /> },
        //     }
        //     console.log(value?.stateList)
        //     return (
        //         <div
        //             style={{
        //                 display: 'flex',
        //                 flexDirection: 'column',
        //                 gap: '4px'
        //             }}
        //         >
        //             {value?.stateList?.map((item, key) => {
        //                 return (
        //                     <div>
        //                         <Chip
        //                             key={key}
        //                             label={`${status[item.state]?.title} - ${item.quantity}`}
        //                             color={status[item.state]?.color}
        //                             icon={status[item.state]?.icon}
        //                             size="small"
        //                             variant="outlined"
        //                         />
        //                     </div>
        //                 )
        //             })}
        //         </div>
        //     )
        // }
        render: (value) => {
            console.log(value.stateList)
            return (
                <>
                    <PercentageBar data={value.stateList} />
                </>
            )
        }
    },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: true,
        recommandation: ['inventory_id', 'designation'],
        showView: '/app/agroforestry/nursery/inventory/view'
    }
]

function InventoryList(props) {
    const { haveAccessTo, service, needLoad, currentPage, Form, drawer, setNeedLoad, filterFromList } = props
    return (
        <>
            <Table
                title="Inventaire - fiches"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                filterFromList={filterFromList}
                hideToolbar={false}
                drawer={drawer}
                hideFilterButton={true}
                showSiteButton={false}
            />
        </>
    )
}

export default InventoryList

// Définir une correspondance entre les états et les couleurs
const stateColors = {
    Neuf: '#30d744',
    Bon: '#30d744',
    Moyen: '#f2fc2e',
    Mauvais: '#fc6e2e'
}

const PercentageBar = ({ data }) => {
    // Calculer le total des quantités
    const total = data.reduce((acc, item) => acc + item.quantity, 0)

    return (
        <Box>
            {/* Barre de pourcentage empilée */}
            <Box
                display="flex"
                height={30}
                width="100%"
                borderRadius={1}
                overflow="hidden"
                sx={{ backgroundColor: '#e0e0e0' }}
            >
                {data.map((item, index) => {
                    const percentage = (item.quantity / total) * 100
                    return (
                        <Box
                            key={index}
                            sx={{
                                width: `${percentage}%`,
                                backgroundColor: stateColors[item.state] || '#757575',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#fff',
                                transition: 'width 0.5s',
                                fontSize: '0.875rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}
