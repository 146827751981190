import React from 'react'
import Table from '../../../../components/DataTable/Table'

const headCells = [
    {
        id: 'code_plat_drain',
        label: 'ID Plate bande',
        link: 'true',
        showView: { link: '/app/mangrove/nursery/plat/edit', param: 'platDrainId' }
    },
    { id: 'date', label: 'Date de suivi', type: 'date' },
    { id: 'nbr_refilled', label: 'Nombre de jeunes plants regarnis' },
    { id: 'average_number', label: 'Nombre moyen des feuilles' },
    { id: 'average_height', label: 'Hauteur moyenne de jeunes plants' },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: true,
        showView: '/app/mangrove/nursery/tracking/edit'
    }
]

function PlatDrainTrackingTable(props) {
    const { haveAccessTo, service, needLoad, currentPage, Form, setNeedLoad, drawer } = props
    return (
        <>
            <Table
                title="Détail de suivi - Plate-bande"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                hideFilterButton={true}
                showSiteButton={true}
                drawer={drawer}
            />
        </>
    )
}

export default PlatDrainTrackingTable
