import { Container, Grid } from '@mui/material'
import Popup from '../../../../../../layouts/Popup'
import Controls from '../../../../../../components/Entry/Controls'
import { useState } from 'react'

function AddVegetation(props) {
    const {
        openPopup,
        setOpenPopup,
        onSave,
        dataVegetation = [],
        values,
        errors,
        handleInputChange,
        setValues,
        speciesDataAdd
    } = props

    const [data, setData] = useState({})

    const handleSaveData = () => {
        onSave([...dataVegetation, data])
        setValues({ ...values, exist_vegetation: [...dataVegetation, data] })
        setOpenPopup(false)
    }
    return (
        <>
            <Popup title={'Végétations'} sx={{ width: '400px' }} setOpenPopup={setOpenPopup} openPopup={openPopup}>
                <Grid container>
                    <Container>
                        <Controls.AutoCompleteSelect
                            label={'Choisir une espèce'}
                            options={speciesDataAdd}
                            labelKey={'name'}
                            valueKey={'name'}
                            onChange={(e) => {
                                setData({ ...data, name: e.target.value })
                            }}
                            value={data?.name}
                        ></Controls.AutoCompleteSelect>
                        <Controls.Input
                            label="Pourcentage (%)"
                            name="percentage"
                            type='number'
                            onChange={(e) => {
                                setData({ ...data, percentage: e.target.value })
                                handleInputChange(e)
                            }}
                        />
                    </Container>
                    <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Controls.Button onClick={handleSaveData} text="Ajouter" size={'small'} />
                    </Container>
                </Grid>
            </Popup>
        </>
    )
}

export default AddVegetation
