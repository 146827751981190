import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Controls from '../../../components/Entry/Controls'
import OrderTrackingFormAgro from './OrderTrackingFormAgro'
import { Container } from '@mui/material'
import SectionTitle from '../../../components/Typography/SectionTitle'
import { useForm } from '../../../applications/hooks/UseForm'
import { OrderTrackingFormMangrove } from './OrderTrackingFormMangrove'

const { Input: TextField } = Controls

const initialValues = {
    customer_id: '',
    project_type: '',
    plantation_type: '',
    tree_ordered: '',
    location: '',
    state: '',
    plot: '',
    plantation_date: new Date(),
    surface: 0
}

const types = [{ label: 'Agroforesterie' }, { label: 'Mangroves' }]

export const OrderTrackingForm = (props) => {
    const { records, setNeedLoad } = props
    const location = useLocation().pathname.split('/')[2]

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('customer_id' in fieldValues) temp.customer_id = fieldValues.customer_id ? '' : 'Ce champ est requis.'
        if ('project_type' in fieldValues) temp.project_type = fieldValues.project_type ? '' : 'Ce champ est requis.'
        if ('plantation_type' in fieldValues)
            temp.plantation_type = fieldValues.plantation_type ? '' : 'Ce champ est requis.'
        if ('tree_ordered' in fieldValues) temp.tree_ordered = fieldValues.tree_ordered ? '' : 'Ce champ est requis.'
        if ('location' in fieldValues) temp.location = fieldValues.location ? '' : 'Ce champ est requis.'
        if ('state' in fieldValues) temp.state = fieldValues.state ? '' : 'Ce champ est requis.'
        if ('plot' in fieldValues) temp.plot = fieldValues.plot ? '' : 'Ce champ est requis.'
        if ('plantation_date' in fieldValues)
            temp.plantation_date = fieldValues.plantation_date ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialValues, true, validate)

    useEffect(() => {
        console.log('location >>>>>>>>>>>> ', location)
        if (records) {
            console.log('records', records)
            setValues({
                ...values,
                id: records?.id || '',
                idCustomer: records?.customers?.id || '',
                customer_id: records?.customers?.firstname || '',
                project_type: records?.project_type || '',
                plantation_type: records?.plantation_type || '',
                tree_ordered: records?.tree_ordered || '',
                location: records?.location || '',
                agro_type: records?.agro_type || '',
                state: records?.state || '',
                plot: records?.plot_id || '',
                plantation_date: records?.plantation_date || new Date(),
                surface: records?.surface || 0
            })
        }
    }, [records])

    return (
        <div>
            <Container style={{ padding: '20px 10px 20px 10px' }}>
                <SectionTitle>Informations sur la commande</SectionTitle>
                <div className="flex gap-4">
                    <Controls.Input
                        id="outlined-disabled"
                        label="Client"
                        name="customer_id"
                        value={values?.customer_id || ''}
                        size="small"
                        // onChange={handleChange}
                        error={errors?.customer_id}
                        isDisabled={true}
                        // isRequired={true}
                    />
                    <Controls.Input
                        id="outlined-disabled"
                        label="Type de projet"
                        name="project_type"
                        value={values?.project_type || ''}
                        size="small"
                        // onChange={handleChange}
                        isDisabled={true}
                        error={errors?.project_type}
                        // isRequired={true}
                    />
                    <Controls.Input
                        id="outlined-disabled"
                        label="Type de plantation"
                        name="plantation_type"
                        value={values?.plantation_type || ''}
                        size="small"
                        // onChange={handleChange}
                        isDisabled={true}
                        error={errors?.plantation_type}
                        // isRequired={true}
                    />
                </div>
                <div className="flex gap-4">
                    <Controls.Input
                        id="outlined-disabled"
                        label="Nombre d'arbres commandés"
                        name="tree_ordered"
                        value={values?.tree_ordered || ''}
                        size="small"
                        type="number"
                        // onChange={handleChange}
                        isDisabled={true}
                        error={errors?.tree_ordered}
                        // isRequired={true}
                    />
                    <div
                        style={{
                            display: values?.idCustomer === 1 || values?.idCustomer === 2 ? 'none' : 'contents'
                        }}
                    >
                        <Controls.Input
                            id="outlined-disabled"
                            label="Type d'agro"
                            name="agro_type"
                            value={values?.agro_type || ''}
                            size="small"
                            // onChange={handleChange}
                            isDisabled={true}
                            error={errors?.agro_type}
                            // isRequired={true}
                        />
                    </div>
                    <Controls.Input
                        id="outlined-disabled"
                        label="Localisation demandée"
                        name="location"
                        value={values?.location || ''}
                        size="small"
                        // onChange={handleChange}
                        isDisabled={true}
                        error={errors?.location}
                        // isRequired={true}
                    />
                </div>
                {location !== 'commercial' && (
                    <>
                        <>
                            {records?.plantation_type === 'Agroforesterie' ? (
                                <OrderTrackingFormAgro records={records} setNeedLoad={setNeedLoad} />
                            ) : (
                                <OrderTrackingFormMangrove records={records} setNeedLoad={setNeedLoad} />
                            )}
                        </>
                    </>
                )}
                {location === 'commercial' && records?.state !== 'Commande à traiter' && (
                    <>
                        {records?.plantation_type === 'Agroforesterie' ? (
                            <OrderTrackingFormAgro records={records} setNeedLoad={setNeedLoad} />
                        ) : (
                            <OrderTrackingFormMangrove records={records} setNeedLoad={setNeedLoad} />
                        )}
                    </>
                )}
            </Container>
        </div>
    )
}
