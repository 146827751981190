import React from 'react'
import { Container, Grid } from '@mui/material'
import Controls from '../../../../../../../../components/Entry/Controls'
import { Form, useForm } from '../../../../../../../../applications/hooks/UseForm'

const initialValues = {
    plant_lived: '',
    plant_germed: '',
    problems: '',
    plant_dead: '',
    plant_ready: '',
    soluces: '',
    tracking_date: new Date().toISOString()
}
function AddTrackingSemis(props) {
    const { addOrEdit, objectRelated } = props
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('plant_lived' in fieldValues) temp.plant_lived = fieldValues.plant_lived ? '' : 'Ce champ est requis.'
        if ('plant_germed' in fieldValues) temp.plant_germed = fieldValues.plant_germed ? '' : 'Ce champ est requis.'
        if ('problems' in fieldValues) temp.problems = fieldValues.problems ? '' : 'Ce champ est requis.'
        if ('plant_dead' in fieldValues) temp.plant_dead = fieldValues.plant_dead ? '' : 'Ce champ est requis.'
        if ('plant_ready' in fieldValues) temp.plant_ready = fieldValues.plant_ready ? '' : 'Ce champ est requis.'
        if ('soluces' in fieldValues) temp.soluces = fieldValues.soluces ? '' : 'Ce champ est requis.'
        if ('tracking_date' in fieldValues) temp.tracking_date = fieldValues.tracking_date ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, errors, setErrors, handleInputChange, resetForm } = useForm(initialValues, true, validate)

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(errors)
        console.log(validate())
        if (validate()) {
            addOrEdit(
                {
                    ...values,
                    nursery_semis_id: objectRelated.id,
                    tracking_date: values?.tracking_date || new Date()
                },
                resetForm
            )
        }
    }
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item sx={6}>
                        <Container>
                            <Controls.Input
                                label="Nombre de vivants"
                                name="plant_lived"
                                value={values.plant_lived}
                                onChange={handleInputChange}
                                error={errors.plant_lived}
                                type="number"
                                isRequired={true}
                                />
                            <Controls.Input
                                label="Nombre de plants ayant germés"
                                name="plant_germed"
                                value={values.plant_germed}
                                onChange={handleInputChange}
                                error={errors.plant_germed}
                                type="number"
                                isRequired={true}
                                />
                            <Controls.Input
                                label="Problème rencontrés"
                                name="problems"
                                value={values.problems}
                                onChange={handleInputChange}
                                error={errors.problems}
                                isRequired={true}
                                />
                            <Controls.DatePicker
                                label="Date de suivi"
                                name="tracking_date"
                                value={values?.tracking_date}
                                onChange={handleInputChange}
                                error={errors.tracking_date}
                                isRequired={true}
                                />
                        </Container>
                    </Grid>
                    <Grid item sx={6}>
                        <Container>
                            <Controls.Input
                                label="Nombre de jeunes plants morts"
                                name="plant_dead"
                                value={values.plant_dead}
                                onChange={handleInputChange}
                                error={errors.plant_dead}
                                type="number"
                                isRequired={true}
                                />
                            <Controls.Input
                                label="Nombre des plants prêts à être reboisé"
                                name="plant_ready"
                                value={values.plant_ready}
                                onChange={handleInputChange}
                                error={errors.plant_ready}
                                type="number"
                                isRequired={true}
                                />
                            <Controls.Input
                                label="Solutions proposées"
                                name="soluces"
                                value={values.soluces}
                                onChange={handleInputChange}
                                error={errors.soluces}
                                isRequired={true}
                                />
                        </Container>
                    </Grid>
                </Grid>
                <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Controls.Button type="submit" text="Enregistrer" />
                </Container>
            </Form>
        </>
    )
}

export default AddTrackingSemis
